import React, { useState } from "react";
import { Search, Smartphone, Lock, Bell, Eye, Video, Phone, Scan, User2, ScanEye, Cable, Square, SquareActivityIcon } from "lucide-react";
import WhyChosseUsHeroImg from "../../assets/homePage/whyChoseUs.jpg";
import twoBannerImg from "../../assets/doorvi/bannner (1)/dobleDevice.jpg";
import img4 from "../../assets/doorvi/business/4.png";
import img3 from "../../assets/doorvi/business/2.jpg";
import img2 from "../../assets/homePage/img4.jpg";


const Section3 = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const renderIcon = (Icon) => {
    return Icon ? <Icon  className="w-10 h-10 text-blue-600"/> : null;
  };
  const onCurrentTabChange = (index) => {
    setCurrentTab(index);
  };
  const doorViFeatures = [
    {
      img: img2,
      title: "Wireless Access Control with Video & Audio Verification",
      description:
        "DoorVi combines access control with smart video and audio features for seamless and secure visitor entry.",
      features: [
        {
          icon:Bell,
          name: "Get Notified",
          detail:
            "When a visitor scans the QR code, the host gets an instant notification with the visitor's details.",
        },        
        {
          icon:Video,
          name: "Video Security",
          detail:
            "Hosts can see who’s at the door before granting access for added safety.",
        },
        {
          icon:Eye,
          name: "Easy Access",
          detail:
            "Hosts can unlock doors remotely with just a tap, ensuring secure and smooth entry.",
        },
        {
          icon:Phone,
          name: "Real-Time Communication",
          detail:
            "Audio calls make it easy to verify visitors or give instructions instantly.",
        },
      ],
    },
    {
      img: img4,
      title: "Visitor Management with Video & Audio Calling",
      description:
        "Make visitor check-ins quick and easy with DoorVi’s smart QR code system and calling features.",
      features: [
        {
          icon:Scan,
          name: "Scan & Select",
          detail:
            "Visitors scan the QR code (for the whole society or specific towers like Tower A or B) and choose the flat and member they want to contact.",
        },
        {icon:Video,
          name: "Audio/Video Call",
          detail:
            "Visitors can start a video or audio call with the host or any authorized member.",
        },
        {icon:User2,
          name: "Add Members",
          detail:
            "Multiple members can join the call for flexibility, making communication easier.",
        },
        {icon:ScanEye,
          name: "Customizable",
          detail:
            "The system can be tailored to fit the unique needs of your society.",
        },
      ],
    },
    {
      img: img3,
      title: "DoorVi Intercom",
      description:
        "DoorVi connects easily to existing intercom systems, offering a complete solution for access control and visitor management.",
      features: [
        {
          icon:Cable,
          name: "Stay Connected",
          detail:
            "Talk with your neighbour’s using the intercom feature—no hardware needed.",
        },
        {icon:Video,
          name: "Seamless Video and Audio Calls",
          detail:
            "Make both video and audio calls directly through the DoorVi app.",
        },
        {icon:Lock,
          name: "Privacy for Secure Communication",
          detail: " Keep your phone number private for secure communication.",
        },
        {icon:SquareActivityIcon,
          name: "Effortless Connections at Your Fingertips",
          detail: "Enjoy easy, hassle-free connections with just a tap.",
        },
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-white px-4 sm:px-6 lg:px-8 py-16">
      <div className="w-full flex items-center justify-center mt-16 mb-16">
        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400 cursor-pointer">
          <li
            className="me-2 ml-2"
            onClick={() => {
              setCurrentTab(0);
            }}
          >
            <p
              className={`${
                currentTab == 0
                  ? "bg-blue-600 text-white"
                  : "text-neutral-600 bg-neutral-100"
              } inline-block px-12 py-10  rounded-full active`}
            >
              Wireless Access Control
            </p>
          </li>
          <li
            className="me-2 ml-2"
            onClick={() => {
              setCurrentTab(1);
            }}
          >
            <p
              className={`${
                currentTab == 1
                  ? "bg-blue-600 text-white"
                  : "text-neutral-600 bg-neutral-100"
              } inline-block px-12 py-10  rounded-full active`}
            >
              Visitor Management
            </p>
          </li>
          <li
            className="me-2 ml-2"
            onClick={() => {
              setCurrentTab(2);
            }}
          >
            <p
              className={`${
                currentTab == 2
                  ? "bg-blue-600 text-white"
                  : "text-neutral-600 bg-neutral-100"
              } inline-block px-12 py-10  rounded-full active`}
            >
              Intercom Integration
            </p>
          </li>
        </ul>
      </div>
      <div className="max-w-[80%] mx-auto" style={{ marginTop: "100px" }}>
        <div className="w-full">
          <div className="grid lg:grid-cols-2 gap-12">
            {/* Left Column */}
            <div className="space-y-8">
              <h1 className="text-3xl font-bold mb-4">
                {doorViFeatures[currentTab].title}
              </h1>
              {/* <p className="text-gray-600 mb-8 text-[24px]">
                {doorViFeatures[currentTab].title}
              </p> */}
              <div className="space-y-6">
                <div className="flex gap-4">
                  <div className="flex-shrink-0">
                  {renderIcon(doorViFeatures[currentTab].features[0].icon)}
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900 mb-2">
                      {doorViFeatures[currentTab].features[0].name}
                    </h3>
                    <p className="text-gray-600 text-2xl leading-relaxed text-[24px]">
                      {doorViFeatures[currentTab].features[0].detail}
                    </p>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-shrink-0">
                  {renderIcon(doorViFeatures[currentTab].features[1].icon)}
                  </div>
                  <div>
                    <h3 className="font-semibold text-xxl text-gray-900 mb-2">
                      {doorViFeatures[currentTab].features[1].name}
                    </h3>
                    <p className="text-gray-600 text-2xl leading-relaxed text-[24px]">
                      {doorViFeatures[currentTab].features[1].detail}
                    </p>
                  </div>
                </div>

                <div className="flex gap-4">
                  <div className="flex-shrink-0">
                  {renderIcon(doorViFeatures[currentTab].features[2].icon)}
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900 mb-2">
                      {doorViFeatures[currentTab].features[2].name}
                    </h3>
                    <p className="text-gray-600 text-2xl leading-relaxed text-[24px]">
                      {doorViFeatures[currentTab].features[2].detail}
                    </p>
                  </div>
                </div>

                <div className="flex gap-4">
                  <div className="flex-shrink-0">
                  {renderIcon(doorViFeatures[currentTab].features[3].icon)}
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900 mb-2">
                      {doorViFeatures[currentTab].features[3].name}
                    </h3>
                    <p className="text-gray-600 text-2xl leading-relaxed text-[24px]">
                      {doorViFeatures[currentTab].features[3].detail}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Right Column */}
            <div>
              <img
                src={doorViFeatures[currentTab].img}
                alt="Visitor Management System"
                className="w-[90%] rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
        {/* Why Choose Section */}
        <div className="mt-24">
          <h2 className="text-6xl font-bold mb-12">Why Choose DoorVi?</h2>

          <div className="grid lg:grid-cols-3 gap-x-24 gap-y-12">
            <div className="col-span-1">
              <img
                src={WhyChosseUsHeroImg}
                alt="Mobile App Interface"
                className="w-full mb-8"
              />
            </div>

            <div className="lg:col-span-2 grid md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-3xl font-semibold mb-2">
                  Affordable & Simple
                </h3>
                <p className="text-gray-600 text-[24px] mb-2">
                  No need for expensive hardware or messy wiring. Just place the
                  QR codes, and let DoorVi’s app-based system do the work.
                </p>
              </div>

              <div>
                <h3 className="text-3xl font-semibold mb-2">
                  Smart Intercom Features
                </h3>
                <p className="text-gray-600 text-2xl mb-2">
                  With DoorVi's intercom feature, apartment residents can make
                  video or audio calls with neighbors. It keeps phone numbers
                  private, ensuring secure and easy communication through the
                  app.{" "}
                </p>
              </div>

              <div>
                <h3 className="text-3xl font-semibold mb-2">
                  Multi Credentials
                </h3>
                <p className="text-gray-600 text-2xl mb-2">
                  Replace traditional keycards with secure smartphone-based
                  access credentials for added convenience and security.
                </p>
              </div>

              <div>
                <h3 className="text-3xl font-semibold mb-2">
                  Convenience with Security
                </h3>
                <p className="text-gray-600 text-2xl mb-2">
                  Manage visitor access, monitor entries, and connect with
                  visitors directly from your phone with video and audio calls.
                </p>
              </div>
              <div>
                <h3 className="text-3xl font-semibold mb-2">
                  Smart Lock Integration
                </h3>
                <p className="text-gray-600 text-2xl mb-2">
                  DoorVi offers lock integration, allowing you to unlock doors
                  wirelessly using your phone through the app. This feature
                  makes access management simpler, more secure, and incredibly
                  convenient.
                </p>
              </div>
              <div>
                <h3 className="text-3xl font-semibold mb-2">Eco-Friendly</h3>
                <p className="text-gray-600 text-2xl mb-2">
                  Go wireless to save on materials, reduce energy use, and make
                  a positive impact on the environment.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Secure Your Property Section */}
        <div className="mt-24 flex flex-col lg:flex-row items-start gap-12">
          <div className="lg:w-1/2 lg:mt-[300px]">
            <h1 className="text-7xl font-bold mb-4">
              Book Your Demo Now
            </h1>
            <p className="text-gray-600 mb-8 text-[30px]">
              Get in touch with a DoorVi Expert
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfRjvXWMClU5ucAxS2MAeki6RREn7liemspbHf4JsJi3ypVQw/viewform"
              target="_blank"
              className="bg-blue-600 text-white px-6 py-3 rounded hover:bg-blue-700 transition-colors shadow-lg"
            >
              Connect With Us
            </a>
          </div>
          <div className="lg:w-1/2">
            <img src={twoBannerImg} alt="Security Device" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
