import React from 'react'
import Section1 from '../../components/homeComponants/Section1'
import Section2 from '../../components/homeComponants/Section2'
import Section3 from '../../components/homeComponants/Section3'
import Section4 from '../../components/homeComponants/Section4'
import Section5 from '../../components/homeComponants/Section5'

const MainPage = () => {
  return (
    <>
      <Section1/>
      <Section2/>
      <Section3/>
      <Section4/>
      <Section5/>
    </>
  )
}

export default MainPage
