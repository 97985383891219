import React from 'react';
import {Building, Key } from 'lucide-react';
import DownloadImg from "../../assets/doorvi/bannner (1)/downloadApp.jpg";
import appleStore from '../../assets/images/app-store.jpg';
import playSotre from '../../assets/images/google-play.jpg';
import { QrCode, Bell, Smartphone, Shield, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

import MultiDSimg from "../../assets/homePage/image.png";

function Section5() {
  return (
    <>      
      
      <header className="w-[100%] md:w-[80%] mx-auto px-4 py-8 md:py-16">        
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1558002038-1055907df827?auto=format&fit=crop&w=800&q=80"
              alt="Smart doorbell interface"
              className="rounded-2xl shadow-2xl"
            />
            <div className="absolute -bottom-6 -left-6 bg-white p-4 rounded-xl shadow-lg hidden md:block">
              <div className="flex items-center space-x-3">
                <div className="bg-blue-100 p-2 rounded-lg">
                  <Shield className="w-6 h-6 text-blue-600" />
                </div>
                <div>
                  <p className="font-medium text-gray-900">Secure Access</p>
                  <p className="text-[18px] text-gray-500">
                    End-to-end encrypted
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
              DoorVi for Single Homes
            </h1>
            <p className="text-gray-600 text-[24px] mb-8">
              DoorVi is not just for big buildings—it’s the ideal choice for
              single homes, bringing top-notch security, convenience, and
              control right to your doorstep.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Link
                to="/shop"
                className="flex items-center justify-center bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition"
              >
                Shop Now
                <ChevronRight className="w-5 h-5 ml-2" />
              </Link>              
            </div>
          </div>
        </div>
      </header>
      {/* Mobile App Section */}
      <section className="py-16">
        <div className="w-[95%] md:w-[80%] mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">            
            <div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Download the DoorVi App Today
              </h2>
              <p className="text-lg md:text-2xl text-gray-600 mb-8">
              Upgrade your door communication with the DoorVi app – download now to easily connect with visitors using QR code pairing
              </p>              
              <div className="flex flex-col sm:flex-row gap-4">
                <a target='_blank' href='https://play.google.com/store/apps/details?id=com.doorvi'  className="flex items-center justify-center text-white px-8 py-3 rounded-lg hover:bg-neutral-400-800 transition">
                  <img src={playSotre} alt="App Store" className="h-16 shadow-2xl" />
                </a>
                <a href='https://apps.apple.com/in/app/doorvi-door-video-calling/id1634023696' target='_blank' className="flex items-center justify-center  text-white px-8 py-3 rounded-lg hover:bg-neutral-400-800 transition">
                  <img src={appleStore} alt="Play Store" className="h-16 shadow-2xl" />
                </a>
              </div>
            </div>
            <div className="flex justify-center">
              <img
                src={DownloadImg}
                alt="Mobile app interface"
                className="w-[75%]"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Previous sections remain unchanged */}
    </>
  );
}

export default Section5;


{/* <Link to={'https://apps.apple.com/in/app/doorvi-door-video-calling/id1634023696'} target='_blank'> <LazyLoadImage effect="black-and-white"  src={appStore} width={200} /></Link> */}
                                    // <Link to={'https://play.google.com/store/apps/details?id=com.doorvi'} target='_blank'> <LazyLoadImage effect="black-and-white"  src={googlePlay} width={200} /></Link>