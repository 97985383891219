import React, { useEffect } from 'react'
import Loader from "../assets/doorvi/doorvi_round.jpg"
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const RedirectToDetails = ({redirectLink}) => {
    const history=useNavigate();
    useEffect(() => {
       history(redirectLink) 
    }, [])
    return (
        <div className='loader'><LazyLoadImage effect="black-and-white" src={Loader} /></div>
    )
}

export default RedirectToDetails
