import React from "react";
import { QrCode, Bell, Smartphone, Shield, ChevronRight, UserRound, Rss } from "lucide-react";
import { Link } from "react-router-dom";
import {Building, Key } from 'lucide-react';
import DownloadImg from "../../assets/doorvi/bannner (1)/downloadApp.jpg";
import appleStore from '../../assets/images/app-store.jpg';
import playSotre from '../../assets/images/google-play.jpg';

import MultiDSimg from "../../assets/homePage/image.png";
import { Update } from "@mui/icons-material";

function Section4() {
  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}      
      {/* Features Section */}
      <section className="w-[95%] md:w-[90%] mx-auto px-4 py-16">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
            Manage Everything from One Place
            </h2>
            <p className="text-lg md:text-2xl text-gray-600 mb-8">
            DoorVi’s easy-to-use portal gives you full control over access, visitor management, and communication—all in one simple dashboard.
            </p>
            <ul className="space-y-4">
              <li className="flex items-start space-x-3">
                <Building className="w-10 h-10 text-blue-600 " />
                <p className="text-lg md:text-2xl text-gray-600">Centralized Control: Handle visitor logs, access permissions, and communication from one platform.</p>
              </li>
              <li className="flex items-start space-x-3">
                <Key className="w-10 h-10 text-blue-600 " />
                <p className="text-lg md:text-2xl text-gray-600">Easy Operations: No need for multiple systems—everything is in one place for smooth management.</p>
              </li>
              <li className="flex items-start space-x-3">
                <UserRound className="w-10 h-10 text-blue-600" />
                <p className="text-lg md:text-2xl text-gray-600">User-Friendly Design: Simple and intuitive interface, with no complicated setup</p>
              </li>
              <li className="flex items-start space-x-3">
                <Rss className="w-10 h-10 text-blue-600" />
                <p className="text-lg md:text-2xl text-gray-600"> Instant Updates: Receive real-time notifications for visitor activities and access events, keeping you in the loop.</p>
              </li>
              {/* <li className="flex items-start space-x-3">
                <Building className="w-10 h-10 text-blue-600 mt-1" />
                <p className="text-lg md:text-2xl text-gray-600">Manage up to 100 sites on a single system, with tailored access permissions for communal areas</p>
              </li>
              <li className="flex items-start space-x-3">
                <Key className="w-10 h-10 text-blue-600 mt-1" />
                <p className="text-lg md:text-2xl text-gray-600">Simply cancel a lost token without needing to change locks</p>
              </li> */}
            </ul>
          </div>
          <div>
            <img
              src={MultiDSimg}
              alt="Modern apartment building"
              className="rounded-2xl shadow-2xl shadow-current"
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-50 py-16 mt-20">
        <div className="w-[95%] md:w-[80%] mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            How DoorVi Works
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Smartphone className="w-8 h-8 text-blue-600" />,
                title: "Download App",
                description:
                  "Download the DoorVi app from the App Store or Play Store.",
              },
              {
                icon: <QrCode className="w-8 h-8 text-blue-600" />,
                title: "Scan Qr Code",
                description: "Scan the QR code to connect it to your account.",
              },
              {
                icon: <Bell className="w-8 h-8 text-blue-600" />,
                title: "Visitor Alerts",
                description:
                  "Get instant visitor alerts, video calls, and audio calls, all at your fingertips.",
              },
            ].map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                <div className="bg-blue-50 w-16 h-16 rounded-lg flex items-center justify-center mb-4">
                  {feature.icon}
                </div>
                <h3 className="font-semibold text-xxl mb-2">{feature.title}</h3>
                <p className="text-gray-600 text-[23px]">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-[95%] md:w-[80%] mx-auto px-4 py-16">
      <div className="bg-gradient-to-r from-blue-400 to-blue-600 rounded-2xl p-8 md:p-16 text-center">
          <h2 className="text-3xl md:text-7xl font-bold text-white mb-8">
            Ready to Upgrade Your Old Hardware System?
          </h2>
          <p className="text-blue-100 mb-12 max-w-5xl mx-auto">
            Join over 100,000+ happy DoorVi users! DoorVi transforms visitor
            entry, communication, and access control, making it simple and
            efficient.
          </p>
          <Link
            onClick={() => {
              window.scrollTo(0, 0);              
            }}
            to="/contact"
            className="bg-white text-blue-600 px-8 py-3 rounded-lg hover:bg-gray-100 transition"
          >
            Get Started Now
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Section4;
