import React, {
  useEffect,
  useState,
  createContext,
  Suspense,
  lazy,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./responsive.css";
import data from "./data";
import { getAllPlans } from "./api";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader from "./assets/doorvi/doorvi_round.jpg";
import RedirectToDetails from "./pages/RedirectToDetails";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MainPage from "./pages/MainPage/MainPage";

const Header = lazy(() => import("./components/header/header"));
const Footer = lazy(() => import("./components/footer/footer"));
const Home = lazy(() => import("./pages/Home/index"));
const NotFound = lazy(() => import("./pages/NotFound"));
const DetailsPage = lazy(() => import("./pages/Details"));
const CartSlider = lazy(() => import("./components/CartSlider/CartSlider"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const Blogs = lazy(() => import("./pages/Blogs/Blogs"));
const TermsAndCon = lazy(() => import("./pages/Policys/TermsAndCon"));
const RefundPolicy = lazy(() => import("./pages/Policys/RefundPolicy"));
const ShippingPolicy = lazy(() => import("./pages/Policys/ShippingPolicy"));
const PrivatePolicy = lazy(() => import("./pages/Policys/PrivatePolicy"));
const FullBlog = lazy(() => import("./pages/Blogs/FullBlog"));
const ReferAndEarn = lazy(() => import("./pages/referAndEarn/ReferAndEarn"));
const ThankYou = lazy(() => import("./pages/Thank-you/ThankYou"));
const CheckoutSuccess = lazy(() => import("./components/CheckoutSuccess"));
const RazorpayCheckout = lazy(() =>
  import("./pages/razorpayCheckout/RazorpayCheckout")
);
const Faq = lazy(() => import("./pages/faq/Faq"));

Sentry.init({
  // dsn: "https://6ce65ed40641dffd5bb89a9a16152d89@o4507049352757248.ingest.us.sentry.io/4507055149416448",
  dsn: "https://11ccbf739d043f0b72e61df65e118bac@o4507128630542336.ingest.de.sentry.io/4507128633294928",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.metrics.metricsAggregatorIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const MyContext = createContext();

function App() {
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isopenNavigation, setIsopenNavigation] = useState(false);
  const [isLogin, setIsLogin] = useState();
  const [isShowCart, setIsShowCart] = useState(false);
  const [isOpenFilters, setIsopenFilters] = useState(false);
  const [plans, setPlans] = useState([]);
  const [country, setCountry] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();

  const getAllProdPlans = async (country) => {
    try {
      const res = await getAllPlans();
      if (res) {
        let updatedPlans = res.data;
        if (country !== "IN") {
          updatedPlans = res.data.filter(
            (plan) => plan.subscriptionUniqueName !== "com.doorvi.basic"
          );
        }
        setPlans(updatedPlans);
        setSelectedPlan(updatedPlans[country === "IN" ? 1 : 0]);
      } else {
        throw new Error("Failed to fetch plans");
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
      alert("Something went wrong. Please try again later.");
    }
    // if(type==="OrderOnline.html")
    setIsloading(false);
  };

  const getGeoInfo = () => {
    axios
      .get("https://extreme-ip-lookup.com/json/?key=TIWLQiybO4SRhDrp6uT0")
      .then((response) => {
        const country = response.data.countryCode;
        // console.log("country details", country);
        setCountry(country);
        getAllProdPlans(country);
      })
      .catch((error) => {
        console.error("Error fetching geo info:", error);
      });
  };

  useEffect(() => {
    const isIE = !!document.documentMode;
    const isFirefox = typeof InstallTrigger !== "undefined";
    if (!isIE && !isFirefox) {
      document.body.style.zoom = "90%";
    }
  }, []);

  useEffect(() => {
    getGeoInfo();
  }, []);

  const replaceElementInArray = (array, newElement) => {
    // console.log("current array", array);
    const index = array.findIndex(
      (item) =>
        item.product.id === newElement.product.id &&
        item.plan.subscriptionUniqueName ===
          newElement.plan.subscriptionUniqueName
    );
    if (index !== -1) {
      array[index] = newElement;
    }
    return array;
  };

  const addToCart = (_item) => {
    try {
      const item = { ..._item }; // clone the item
      // console.log("item from app", item);
      const isAlreadyInCart = cartItems.some(
        (obj) =>
          obj.product.id === item.id &&
          obj.plan.subscriptionUniqueName ===
            selectedPlan.subscriptionUniqueName
      );
      if (isAlreadyInCart) {
        toast.error("Product is already in cart", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        onCartButtonPressed();
        return;
      } else {
        item.quantity = 1;
        cartItems.push({ product: item, plan: selectedPlan });
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
    onCartButtonPressed();
    // console.log(cartItems);
  };

  const incressQty = (item, qty) => {
    // console.log("item from incress qty", item)
    item.product.quantity = qty;
    replaceElementInArray(cartItems, item);
  };
  const decressQty = (item, qty) => {
    item.product.quantity = qty;
    replaceElementInArray(cartItems, item);
  };
  const removeItemsFromCart = (item) => {
    // console.log("product from removefromcart fun", item)
    const arr = cartItems.filter(
      (obj) =>
        obj.product.id !== item.product.id ||
        obj.plan.subscriptionUniqueName !== item.plan.subscriptionUniqueName
    );
    setCartItems(arr);
  };

  const emptyCart = () => {
    setCartItems([]);
  };

  const onCartButtonPressed = () => {
    setIsShowCart(!isShowCart);
  };

  const value = {
    cartItems,
    isLogin,
    windowWidth,
    isOpenFilters,
    addToCart,
    removeItemsFromCart,
    emptyCart,
    isopenNavigation,
    setIsopenNavigation,
    plans,
    country,
    selectedPlan,
    setSelectedPlan,
    incressQty,
    decressQty,
  };
  return (
    <Suspense
      fallback={
        <div className="loader">
          <LazyLoadImage effect="black-and-white" src={Loader} />
        </div>
      }
    >
      <BrowserRouter>
        <MyContext.Provider value={value}>
          <ToastContainer />
          {isLoading === true && (
            <div className="loader">
              <LazyLoadImage effect="black-and-white" src={Loader} />
            </div>
          )}
          {isLoading == false && (
            <>
              <Header
                data={data.productData}
                onCartButtonPressed={onCartButtonPressed}
              />
              <CartSlider
                isShowCart={isShowCart}
                onCartButtonPressed={() => {
                  setIsShowCart(false);
                }}
              />
              <Routes>
                <Route
                  exact={true}
                  path="/shop"
                  element={
                    <Home
                      data={data.productData}
                      onCartButtonPressed={onCartButtonPressed}
                    />
                  }
                />
                <Route exact={true} path="/" element={<MainPage />} />
                <Route
                  exact={true}
                  path="/product/:id"
                  element={
                    <DetailsPage
                      data={data.productData}
                      onCartButtonPressed={onCartButtonPressed}
                    />
                  }
                />
                <Route
                  exact={true}
                  path="/OrderOnline.html"
                  element={
                    <RedirectToDetails
                      redirectLink={
                        "/product/Qr-Code-DoorBell-Powered-by-Doorvi"
                      }
                    />
                  }
                />
                <Route
                  exact={true}
                  path="/OrderOnline.html"
                  element={
                    <RedirectToDetails
                      redirectLink={
                        "/product/Qr-Code-DoorBell-Powered-by-Doorvi"
                      }
                    />
                  }
                />
                <Route
                  exact={true}
                  path="/OrderOnline_Vehicles.html"
                  element={
                    <RedirectToDetails
                      redirectLink={
                        "/product/doorvi-qr-code-technology-help-you-to-connected-with-your-vehicle"
                      }
                    />
                  }
                />
                <Route
                  exact={true}
                  path="/OrderOnline_Hotels.html"
                  element={
                    <RedirectToDetails
                      redirectLink={
                        "/product/doorvi-qr-code-enhances-communication-&-security-in-hotel"
                      }
                    />
                  }
                />
                <Route
                  exact={true}
                  path="/index.html"
                  element={<RedirectToDetails redirectLink={"/"} />}
                />
                <Route exact={true} path="/contact" element={<ContactUs />} />
                <Route
                  exact={true}
                  path="/Contact.html"
                  element={<ContactUs />}
                />
                <Route exact={true} path="/blogs" element={<Blogs />} />
                <Route exact={true} path="/Blogs.html" element={<Blogs />} />
                <Route exact={true} path="/blogs/:id" element={<FullBlog />} />
                <Route exact={true} path="/terms" element={<TermsAndCon />} />
                <Route
                  exact={true}
                  path="/Terms.html"
                  element={<TermsAndCon />}
                />
                <Route
                  exact={true}
                  path="/refund-policy"
                  element={<RefundPolicy />}
                />
                <Route
                  exact={true}
                  path="/Refund-Policy.html"
                  element={<RefundPolicy />}
                />
                <Route
                  exact={true}
                  path="/shipping-policy"
                  element={<ShippingPolicy />}
                />
                <Route
                  exact={true}
                  path="/Shipping-Policy.html"
                  element={<ShippingPolicy />}
                />
                <Route
                  exact={true}
                  path="/privacy-policy"
                  element={<PrivatePolicy />}
                />
                <Route
                  exact={true}
                  path="/PrivacyPolicy.html"
                  element={<PrivatePolicy />}
                />
                <Route
                  exact={true}
                  path="/refer-earn"
                  element={<ReferAndEarn />}
                />
                <Route exact={true} path="/faq" element={<Faq />} />
                <Route exact={true} path="/FAQ.html" element={<Faq />} />
                {/* <Route exact={true} path="/thank-you" element={<ThankYou />} /> */}
                <Route
                  exact={true}
                  path="/checkout-success"
                  element={<CheckoutSuccess />}
                />
                <Route
                  exact={true}
                  path="/razorpay-checkout"
                  element={<RazorpayCheckout />}
                />
                <Route exact={true} path="*" element={<NotFound />} />
              </Routes>
              <Footer />
            </>
          )}
        </MyContext.Provider>
      </BrowserRouter>
    </Suspense>
  );
}

export default Sentry.withProfiler(App);

export { MyContext };
