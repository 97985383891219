// LandingPage.js
import React from 'react';
import mainImage from "../../assets/homePage/homePage.jpeg"
// import mainImage from "../../assets/homePage/homePage.jpeg"
import './styles.css';

const Section1 = () => {
  return (
    <div className="landing-container">
      <div className="main-content">
        <div className="content-grid">
          {/* Left column - Text content */}
          <div className="text-content">
            <div>               
              <h2 className="title-coral">The Future Of</h2>
              <h1 className="title-black">Visitor Management</h1>
              <h1 className="title-black"> Access Control and Intercom Solutions</h1>               
            </div>
             
            <p className="subtitle">
            DoorVi is a fully wireless app-based system that makes managing visitors and controlling access simple. With video and audio calling features, you can stay in control of your property from anywhere. There’s no need for heavy or complicated hardware—just a QR code is enough for smooth intercom calls, easy access control, and better security.


            </p>
            
            <p className="description">
            DoorVi is perfect for homes, offices, and apartments looking for a smart, simple, and sustainable way to manage visitors and access.
            </p>
            
            <p className="description">
            We offer lock integration, so you can unlock doors wirelessly using your phone through the DoorVi app. This makes managing access even easier and more secure.
            
            </p>
            
            {/* Scroll indicator */}
            {/* <div className="scroll-indicator">
              <div className="scroll-dot"></div>
            </div> */}
          </div>
          
          {/* Right column - Image */}
          <div className="image-container">
            <div className="circular-frame text-center flex items-center justify-center">
              <img 
                src={mainImage}
                alt="Building intercom system with QR code and mobile app interface"
                className="shadow-2xl rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;