import React, { useEffect, Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import Section1 from "../../components/homeComponants/Section1";
import Section2 from "../../components/homeComponants/Section2";
import Section3 from "../../components/homeComponants/Section3";
import Section4 from "../../components/homeComponants/Section4";
import Section5 from "../../components/homeComponants/Section5";
import Loader from "../../assets/doorvi/doorvi_round.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MainPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   // Check the hostname
  //   const hostname = window.location.hostname;
  //   console.log(hostname);
  //   if (hostname === "shop.doorvi.co") {
  //     navigate("/shop"); // Redirect to /shop
  //   }
  //   setLoading(false);
  // }, [navigate]);

  return (
    <Suspense
      fallback={
        <div className="loader">
          <LazyLoadImage effect="black-and-white" src={Loader} />
        </div>
      }
    >
      {loading === true ? (
        <div className="loader">
          <LazyLoadImage effect="black-and-white" src={Loader} />
        </div>
      ) : (
        <>
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
        </>
      )}
    </Suspense>
  );
};

export default MainPage;
