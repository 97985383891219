import React from "react";
import { Building2, Lock } from "lucide-react";
import img1 from "../../assets/homePage/apartment complexe.jpg";
import img2 from "../../assets/homePage/doorvi for single house and offices.jpg";
import img3 from "../../assets/homePage/doorvi multi family properties.jpg";
import img4 from "../../assets/homePage/doorvi office spaces.jpg";
import { Link } from "react-router-dom";
const Section2 = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white">
      {/* Hero Section */}
      <div className="max-w-[80%] mx-auto px-4 sm:px-6 lg:px-8 pt-16">
        <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-16">
          <span className="text-coral-500 ">Where </span>Can You Use
          <br />
          DoorVi?
          <br />
        </h1>

        {/* Content Grid */}
        <div className="grid md:grid-cols-2 gap-12 pb-16">
          {/* Apartment Buildings */}
          <div className="group">
            <div className="relative overflow-hidden rounded-lg mb-6">
              <img
                src={img1}
                alt="Apartment Building"
                className="w-full h-[400px] object-cover transform group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-black/20 group-hover:bg-black/30 transition-colors"></div>
            </div>
            <div className="flex items-center gap-3 mb-4">
              <Building2 className="w-6 h-6 text-coral-500" />
              <h2 className="text-2xl font-bold">
                <span className="text-coral-500">
                  Condominium and Apartment
                </span> Properties
              </h2>
            </div>
            <p className="text-gray-600 leading-relaxed text-[24px]">
              Simplify visitor and entry management for both residents and
              guests with ease. DoorVi eliminates the need for hardware
              installations or complex setups, making it a hassle-free solution.
              Residents can seamlessly grant access through their smartphones,
              ensuring convenience and security. Say goodbye to traditional
              systems and hello to smarter, stress-free access management.
            </p>
          </div>

          {/* Large Complexes */}
          <div className="group">
            <div className="relative overflow-hidden rounded-lg mb-6">
              <img
                src={img3}
                alt="Large Complex"
                className="w-full h-[400px] object-cover transform group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-black/20 group-hover:bg-black/30 transition-colors"></div>
            </div>
            <div className="flex items-center gap-3 mb-4">
            <Building2 className="w-6 h-6 text-coral-500" />
              <h2 className="text-2xl font-bold">
                <span className="text-coral-500">Multi-Family </span>  Community
              </h2>
            </div>
            <p className="text-gray-600 leading-relaxed text-[24px]">
              Streamline access management for multiple units with a smart,
              centralized solution. DoorVi allows tenants to easily connect with
              visitors via video or audio calls, all through a single,
              user-friendly app. No need for extra hardware or complex
              systems—tenants can manage entry securely from their smartphones.
              It’s a modern, hassle-free way to enhance convenience and safety
              for your property.
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-12 pb-16">
          {/* Apartment Buildings */}
          <div className="group">
            <div className="relative overflow-hidden rounded-lg mb-6">
              <img
                src={img4}
                alt="Apartment Building"
                className="w-full h-[400px] object-cover transform group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-black/20 group-hover:bg-black/30 transition-colors"></div>
            </div>
            <div className="flex items-center gap-3 mb-4">
              <Building2 className="w-6 h-6 text-coral-500" />
              <h2 className="text-2xl font-bold">
                <span className="text-coral-500">
                  Offices, Co-Working Spaces, Commercial{" "}
                </span>{" "}
                Buildings
              </h2>
            </div>
            <p className="text-gray-600 text-[24px] leading-relaxed">
              Make visitor check-ins faster and more efficient while giving
              employees the power to grant access remotely. With DoorVi, there’s
              no need for key cards, fobs, or additional hardware—everything is
              managed securely through a smart and intuitive app. It’s the
              modern way to enhance access management and security in your
              workspace.
            </p>
          </div>

          {/* Large Complexes */}
          <div className="group">
            <div className="relative overflow-hidden rounded-lg mb-6">
              <img
                src={img2}
                alt="Large Complex"
                className="w-full h-[400px] object-cover transform group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-black/20 group-hover:bg-black/30 transition-colors"></div>
            </div>
            <div className="flex items-center gap-3 mb-4">
            <Building2 className="w-6 h-6 text-coral-500" />
              <h2 className="text-2xl font-bold">
                <span className="text-coral-500">DoorVi for Single </span>Homes
              </h2>
            </div>
            <p className="text-gray-600 leading-relaxed text-[24px]">
              DoorVi is not just for big buildings—it’s the perfect solution for
              single homes, offering advanced security, convenience, and full
              control right at your doorstep. With DoorVi, you can manage
              visitors, grant access remotely, and monitor your home’s security
              from anywhere. The app-based system eliminates the need for
              physical keys or complex hardware, making it an easy and modern
              choice for homeowners looking to enhance their security and ease
              of access <Link className="underline text-2xl" to={"/shop"}>Shop Now</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
